<template>
    <div class="modal profile_modal" v-if="modelValue" :class="{ active : modelValue }">
        <!-- <div class="modal profile_modal right_out" v-if="modelValue" :class="{ active : modelValue }"> -->
        <div class="modal_container">
            <div class="modal_header border-bottom px-5">
                <h1>Custom E-mail Domain Configuration</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="content_wpr">
                    <div class="profile_lifecycles">
                        <div class="lifecycle">
                            <ul>
                                <li :class="{active : domainStep >= 1}">
                                    <span class="icon"><i class="far fa-envelope"></i></span>
                                    <h4>Add Domain<label v-if="domainStep >= 1" class="tag" :class="{success : addStatus}">{{addStatus ? 'success' : 'Pending'}}</label></h4>
                                    <div class="info">{{ form.name ? form.name : 'Please Specify The Domain' }}</div>
                                    <span class="time">{{ moment().format('ll | LT') }}</span>
                                </li>
                                <li :class="{active : domainStep >= 2}">
                                    <span class="icon"><i class="far fa-clipboard"></i></span>
                                    <h4>Add Email Address<label v-if="domainStep >= 2" class="tag" :class="{success : addEmail}">{{addEmail ? 'success' : 'Pending'}}</label></h4>
                                    <div class="info">{{ form.sender_name ? form.sender_name : 'Please Add Email Address' }}</div>
                                    <span class="time">{{ moment().format('ll | LT') }}</span>
                                </li>
                                <li :class="{active : domainStep >= 3}">
                                    <span class="icon"><i class="far fa-file-alt"></i></span>
                                    <h4>Verify Domain<label v-if="domainStep >= 3" class="tag" :class="{ success: verify }">{{ verify ? 'Verified' : 'Pending' }}</label></h4>
                                    <div class="info">Please Verify The Domain</div>
                                    <span class="time">{{ moment().format('ll | LT') }}</span>
                                </li>
                                <li :class="{active : domainStep == 4}">
                                    <span class="icon"><i class="far fa-file-alt"></i></span>
                                    <h4>Activate Domain<label v-if="domainStep == 4" class="tag" :class="{ success : domainActivate }">{{ domainActivate ? 'Activate' : 'Deactivate' }}</label></h4>
                                    <div class="info">Please Verify Domain</div>
                                    <span class="time">{{ moment().format('ll | LT') }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab_content">
                  <div class="setting_wpr" v-if="domainStep == 1">
                      <Form @submit="handleSaveDomain" v-slot="{ errors }">
                          <h2>Add Domain <button type="button" class="config_step" @click="toggleSidebar"><img src="@/assets/images/list.svg" alt=""></button></h2>
                          <div class="form_grp">
                              <div class="group_item">
                                  <label class="input_label">Domain Name</label>
                                  <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                      <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: www.mydomain.com" rules="required" label="domain name" />
                                  </div>
                                  <ErrorMessage name="name" class="text-danger" />
                              </div>
                          </div>
                          <div class="action_wpr mt-4">
                              <button :disabled="domainDNSLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                              <button :disabled="domainDNSLoader" class="btn save_btn">
                                  <i class="fa fa-spin fa-spinner" v-if="domainDNSLoader"></i> {{ domainDNSLoader ? 'Saving' : 'Continue' }}
                              </button>
                          </div>
                      </Form>
                  </div>
                  <div class="setting_wpr" v-if="domainStep == 2">
                      <Form @submit="handleSaveSender" v-slot="{ errors }">
                          <h2>Add E-mail Address</h2>
                          <div class="form_grp">
                              <div class="group_item">
                                  <label class="input_label">E-mail</label>
                                  <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                      <Field autocomplete="off" type="text" name="email" placeholder="johndoe" v-model="form.sender_name" rules="required" />
                                      <span class="prefix"><i class="fas fa-envelope"></i></span>
                                      <span class="prefix-right">@{{ selectedDomain.name }}</span>
                                  </div>
                                  <ErrorMessage name="email" class="text-danger" />
                              </div>
                          </div>
                          <div class="action_wpr mt-4">
                              <button :disabled="domainDNSLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                              <button :disabled="domainDNSLoader" class="btn save_btn">
                                  <i class="fa fa-spin fa-spinner" v-if="domainDNSLoader"></i> {{ domainDNSLoader ? 'Saving' : 'Continue' }}
                              </button>
                          </div>
                      </Form>
                  </div>
                  <div v-if="domainStep == 3">
                      <div class="listing_loader2" v-show="domainDNSLoader"><quote-loader /></div>
                      <div v-if="!domainDNSLoader">
                          <div v-if="domainDNSDetails.domain && domainDNSDetails.domain.id">
                              <h2>Step1:</h2>
                              <div class="extra_info py-2 px-3 mb-5">
                                  <p>Log in to your domain administration panel and find DNS records management panel for the domain: {{ form.sender_name }}@{{ selectedDomain.name }}</p>
                              </div>
                              <h2>Step2:</h2>
                              <h5>Add Sending Records</h5>
                              <div class="result_wpr small m-0">
                                  <div class="actions">
                                      <p class="info2 px-3">TXT records (known as SPF &amp; DKIM) are required to send and receive email with.</p>
                                  </div>
                                  <table>
                                      <thead>
                                          <tr>
                                              <th>Type</th>
                                              <th>Host Name</th>
                                              <th>Enter This Value</th>
                                              <th>Status</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(sendingRecord, sr) of domainDNSDetails.sending_dns_records" :key="sr">
                                              <td>{{ sendingRecord.record_type }}</td>
                                              <td>{{ sendingRecord.name }}</td>
                                              <td>{{ sendingRecord.value }}</td>
                                              <td>{{ sendingRecord.valid == 'valid' ? 'Verified' : 'Unverified' }}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <h5 class="mt-5">Add Receiving Records</h5>
                              <div class="result_wpr small m-0">
                                  <div class="actions">
                                      <p class="info2 px-3">MX records are recommended for all domains, even if you are only sending messages. Unless you already have MX records for {{ form.sender_name }}@{{ selectedDomain.name }} pointing to another email provider (e.g. Gmail), you should update the following records.</p>
                                  </div>
                                  <table>
                                      <thead>
                                          <tr>
                                              <th>Type</th>
                                              <th>Host Name</th>
                                              <th>Priority</th>
                                              <th>Enter This Value</th>
                                              <th>Status</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(recevingRecord, rr) of domainDNSDetails.receiving_dns_records" :key="rr">
                                              <td>{{ recevingRecord.record_type }}</td>
                                              <td>{{ domainDNSDetails.domain.name }}</td>
                                              <td>10</td>
                                              <td>{{ recevingRecord.value }}</td>
                                              <td>{{ recevingRecord.valid == 'valid' ? 'Verified' : 'Unverified' }}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <h5 class="mt-5">Add Tracking  Records</h5>
                              <div class="result_wpr small m-0">
                                  <div class="actions">
                                      <p class="info2 px-3">The CNAME record is necessary for tracking opens, clicks, and unsubscribes.</p>
                                  </div>
                                  <table>
                                      <thead>
                                          <tr>
                                              <th>Type</th>
                                              <th>Host Name</th>
                                              <th>Enter This Value</th>
                                              <th>Status</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(trackingRecord, tr) of domainDNSDetails.tracking_dns_records" :key="tr">
                                              <td>{{ trackingRecord.record_type }}</td>
                                              <td>{{ trackingRecord.name }}</td>
                                              <td>{{ trackingRecord.value }}</td>
                                              <td>{{ trackingRecord.valid == 'valid' ? 'Verified' : 'Unverified' }}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <div class="extra_info py-2 px-3 mt-5">
                                  <p>If you have a problem with domain configuration - contact your domain provider.</p>
                              </div>
                              <div class="action_wpr mt-5">
                                  <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                                  <button type="button" class="btn save_btn" @click="handleVerifyDomain(selectedDomain)">Verify</button>
                              </div>
                          </div>
                          <div v-else class="text-center">
                              <br />
                              <br />
                              <p>Something went wrong while fetching the domain configuration, please try after some time.</p>
                          </div>
                      </div>
                      <div v-else class="text-center profile_spinner_wpr">
                        <quote-loader/>
                      </div>
                  </div>
                  <div class="setting_wpr" v-if="domainStep == 4">
                      <h2>Activate Domain</h2>
                      <label for="activate" class="switch_option capsule_btn">
                          <h5>Activate Domain</h5>
                          <input type="checkbox" id="activate" :true-value="1" :false-value="0" v-model="domainActivate" hidden>
                          <div><span></span></div>
                      </label>
                      <div class="action_wpr mt-4">
                          <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                          <button type="button" class="btn save_btn">Save</button>
                      </div>
                  </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Email Domain Wizard',

        data () {
            return {
                domainStep: 1,
                form: {
                    name: '',
                    sender_name: '',
                },
                addStatus: false,
                addEmail: false,
                verify: false,
                domainActivate: false,
                moment,
                selectedDomain: {},
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            domainActivate (status) {
                const vm = this;

                if (status) {
                    vm.activateDomainPrompt(vm.selectedDomain);
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            domainDNSDetails: state => state.authModule.domainDNSDetails,
            domainDNSLoader: state => state.authModule.domainDNSLoader,
        }),

        methods: {
            ...mapActions({
                saveEmailDomain: 'authModule/saveEmailDomain',
                saveEmailDomainSender: 'authModule/saveEmailDomainSender',
                activateEmailDomain: 'authModule/activateEmailDomain',
                verifyEmailDomain: 'authModule/verifyEmailDomain',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSaveDomain (params, { setFieldError }) {
                const vm = this;
                const form = { name: vm.form.name, setFieldError };

                vm.saveEmailDomain(form).then((domain) => {
                    if (domain) {
                        vm.domainStep = 2;
                        vm.addStatus = true;
                        vm.selectedDomain = domain;

                        if (vm.$parent.$parent && vm.$parent.$parent.getEmailDomains && vm.$parent.$parent.params) {
                            vm.$parent.$parent.getEmailDomains(vm.$parent.$parent.params);
                        } else if (vm.$parent.getEmailDomains  && vm.$parent.params) {
                            vm.$parent.getEmailDomains(vm.$parent.params);
                        }
                    }
                });
            },

            handleSaveSender (params, { setFieldError }) {
                const vm = this;
                const form = { email: `${vm.form.sender_name}@${vm.selectedDomain.name}`, 'email_domain_id': vm.selectedDomain.id, setFieldError };

                vm.saveEmailDomainSender(form).then((result) => {
                    if (result) {
                        vm.domainStep = 3;
                        vm.addEmail = true;

                        if (vm.$parent.$parent && vm.$parent.$parent.getEmailDomains && vm.$parent.$parent.params) {
                            vm.$parent.$parent.getEmailDomains(vm.$parent.$parent.params);
                        } else if (vm.$parent.getEmailDomains  && vm.$parent.params) {
                            vm.$parent.getEmailDomains(vm.$parent.params);
                        }
                    }
                });
            },

            activateDomainPrompt (domain) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Activate Domain?', `This will activate <b>${domain.name}</b> to digitally sign your emails(SPF,DKIM,DMARC).`);
                      option.preConfirm =   function() {
                                                return vm.activateEmailDomain(domain.id).then((result) => {
                                                    if (result && vm.$parent.$parent && vm.$parent.$parent.getEmailDomains && vm.$parent.$parent.params) {
                                                        vm.$parent.$parent.getEmailDomains(vm.$parent.$parent.params);
                                                    } else if (result && vm.$parent.getEmailDomains  && vm.$parent.params) {
                                                        vm.$parent.getEmailDomains(vm.$parent.params);
                                                    }
                                                });
                                            };

                Swal.fire(option);
            },

            handleVerifyDomain (domain) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', `You want to verify ${ domain.name } domain.`);
                option.preConfirm = () => {
                    return vm.verifyEmailDomain(domain.id).then((result) => {
                        if (result) {
                            vm.domainStep = 4;
                            vm.verify     = true;
                        } else if (result === 0) {
                            const option2 = Helper.swalWarningOptions('Verification in process', `This may take up to 24-48 hours. We will send an email to ${vm.user.email} when your domain is verified. Once verified you will be able to set this as your default email sender.`);
                            Swal.fire(option2);
                        }
                    });
                }

                Swal.fire(option);
            },

            toggleSidebar () {
                const ls_bar = document.querySelector('.profile_lifecycles');

                if (ls_bar.classList.contains('show')) {
                    ls_bar.classList.remove('show');
                } else {
                    ls_bar.classList.add('show');
                }
            },
        },
    };
</script>

<style scoped>
    .content_wpr{
        display: flex;
        flex: 1;
        padding: 0;
        position: relative;
        border-radius: 0 0 12px 12px;
        overflow: hidden;
    }
    .profile_lifecycles {
        padding: 20px 30px;
        max-height: 100%;
        overflow-y: overlay;
    }

    .profile_lifecycles .lifecycle ul li .info {
        padding: 8px 15px;
        border-radius: 15px;
    }

    .profile_lifecycles .lifecycle ul li.active .icon {
        background: #153057;
        color: #b0d1ff;
    }

    .tab_content {
        flex: 1;
        padding: 40px;
        background: #fafafb;
        border-left: 1px solid #eaeaea;
        /* height: 630px; */
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-radius: 0 0 12px 0;
    }

    .tab_content::-webkit-scrollbar {
        display: none;
    }

    .tab_content h2 {
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .tab_content h5 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
    }

    .result_wpr table td {
        font-size: 13px;
        line-height: 16px;
    }

    .word-break {
        word-break: break-all;
    }

    .result_wpr table th {
        font-size: 14px;
        line-height: 17px;
    }

    .result_wpr table td:first-child, .result_wpr table th:first-child {
        padding: 10px 15px;
        text-align: left;
    }

    span.time {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #777;
        margin: 5px 0 5px 10px;
        display: block;
    }

    .prefix-right {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #e6e6e6;
        font-size: 14px;
        line-height: 17px;
        font-weight: 300;
        color: #949494;
        padding: 0 15px;
        word-break: break-all;
        max-width: 250px;
    }

    .modal.profile_modal > .modal_container{
        max-height: 700px;
        height: calc(100% - 30px);
        margin: 15px;
    }
    .config_step{
        width: 30px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        padding: 6px;
        line-height: 0;
        background: #fff;
        margin: 0 0 0 auto;
        cursor: pointer;
        display: none;
    }
    @media(max-width: 767px){
        .config_step{
            display: block;
        }
        .profile_lifecycles{
            position: absolute;
            height: 100%;
            max-width: 260px;
            left: -300px;
            top: 0;
            z-index: 1;
            background: #fff;
            border-right: 1px solid #e9e9e9;
            transition: all 0.3s ease-in-out;
            border-radius: 0 0 0 12px;
            padding: 20px;
        }
        .profile_lifecycles.show{
            left: 0;
        }
        .tab_content{
            padding: 30px;
        }
        .tab_content h2{
            font-size: 22px;
            line-height: 30px;
        }
        .profile_lifecycles .lifecycle ul:before{
            left: 17px;
        }
        .profile_lifecycles .lifecycle ul li{
            padding: 12px 0 12px 47px;
        }
        .profile_lifecycles .lifecycle ul li .icon{
            width: 35px;
            height: 35px;
            font-size: 15px;
        }
        .profile_lifecycles .lifecycle ul li h4{
            font-size: 13px;
            line-height: 20px;
        }
        .profile_lifecycles .lifecycle ul li h4 .tag{
            font-size: 9px;
            line-height: 10px;
        }
        .profile_lifecycles .lifecycle ul li .info{
            font-size: 11px;
            line-height: 15px;
            padding: 6px 12px;
        }
        .profile_lifecycles .lifecycle ul li span.time{
            font-size: 9px;
        }
    }
    @media(max-width: 499px){

    }
</style>
